import theme from '@/style';
import {ImageBackground, View} from 'react-native';
import {useTranslation} from 'react-i18next';
import Text from '@/components/basic/text';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import React, {useMemo} from 'react';
import {invitationCodeCardImg, invitationColors} from '../invitation.variables';
import {scaleSize} from '@utils';
import LazyImage from '@basicComponents/image';

export interface InvitationCodeProps {
  code?: string;
  onRefreshCode?: () => void;
  onCopy?: () => void;
}

const InvitationCode: React.FC<InvitationCodeProps> = ({
  code,
  onRefreshCode,
  onCopy,
}) => {
  const i18n = useTranslation();

  const renderCode = useMemo(() => {
    const finallyCode = code || '--------';
    return finallyCode.split('').map((c, i) => (
      <Text
        fontSize={theme.fontSize.xl}
        color={theme.fontColor.brown}
        blod
        key={i}
        style={[theme.font.center]}>
        {c}
      </Text>
    ));
  }, [code]);
  return (
    <View style={[theme.flex.col, theme.margin.btml, theme.borderRadius.l]}>
      <ImageBackground
        style={[
          theme.flex.row,
          theme.flex.centerByCol,
          theme.flex.between,
          {height: scaleSize(114), paddingLeft: 25},
        ]}
        resizeMode="stretch"
        source={invitationCodeCardImg}>
        <View style={[{width: scaleSize(170)}]}>
          <Text color={invitationColors.codeTitle} fontSize={theme.fontSize.m}>
            {i18n.t('invitation.home.code-title')}
          </Text>
          <View
            style={[
              theme.flex.row,
              theme.flex.centerByCol,
              theme.margin.topxs,
            ]}>
            <View
              style={[
                theme.flex.centerByCol,
                theme.flex.between,
                theme.flex.row,
                theme.padding.topxxs,
                theme.flex.flex1,
              ]}>
              {renderCode}
            </View>
            <NativeTouchableOpacity
              activeOpacity={0.8}
              onPress={onRefreshCode}
              style={[theme.margin.leftm]}>
              <LazyImage
                occupancy={'transparent'}
                imageUrl={require('@assets/icons/refresh.webp')}
                width={scaleSize(24)}
                height={scaleSize(24)}
              />
            </NativeTouchableOpacity>
          </View>
        </View>
        <NativeTouchableOpacity
          activeOpacity={0.8}
          style={[theme.flex.row, theme.flex.centerByCol]}
          onPress={onCopy}>
          <ImageBackground
            source={require('@components/assets/icons/button-backImg-primary.webp')}
            resizeMode={'stretch'}
            style={[
              theme.flex.center,
              theme.margin.rightl,
              {width: scaleSize(80), height: scaleSize(28)},
            ]}>
            <Text blod color={theme.fontColor.white}>
              {i18n.t('label.copy')}
            </Text>
          </ImageBackground>
        </NativeTouchableOpacity>
      </ImageBackground>
    </View>
  );
};

export default InvitationCode;
